import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const DynamicRoutes = () => {
  const { coupon } = useParams();
  useEffect(() => {
    switch (coupon) {
      case "30off":
        window.location.replace(
          "https://www.joinnextmed.com/weight_loss?promo=START30"
        );
        break;
      case "get20":
        window.location.replace(
          "https://www.joinnextmed.com/weight_loss?promo=20THANKS"
        );
        break;
      case "save15":
        window.location.replace(
          "https://www.joinnextmed.com/weight_loss?promo=THANKS15"
        );
        break;
      default:
        window.location.replace("https://www.joinnextmed.com");
    }
  }, [coupon]);

  return <div></div>;
};

export default DynamicRoutes;
